import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

export function usePostDashboard(post, setIsFrameLoading) {
  // const { t: translate } = useTranslation();

  const location = useLocation();
  const newDashboardId = location.pathname.split('/')[2];

  useEffect(() => {
    if (!newDashboardId) return;
    setIsFrameLoading(true);

    const postDashboardData = async () => {
      await post({
        url: '/dashboard',
        payload: { dashboardId: newDashboardId },
      });
    };

    postDashboardData();
  }, [newDashboardId, post, setIsFrameLoading]);
}

export function useIFrameLoading(setIsFrameLoading) {
  useEffect(() => {
    const handleIFrameLoading = (event) => {
      if (event.origin !== 'https://caf.metabaseapp.com') {
        return;
      }

      // Detects when the metabase iframe is loaded
      if (event.data?.metabase) {
        setIsFrameLoading(false);
      }
    };

    // Register the event listener
    window.addEventListener('message', handleIFrameLoading);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('message', handleIFrameLoading);
    };
  }, [setIsFrameLoading]);
}

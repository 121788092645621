import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { useSecurityOptions } from 'hooks/useSecurityOptions';
import { useScroll } from 'hooks/scroll';
import { useAuth } from 'hooks/auth';
import { useIdleTimer } from 'react-idle-timer';
import Cookies from 'js-cookie';
import { useHeaderFilter } from 'hooks/headerFilter';
import DataHeader from 'components/DataHeader';
import Sidebar from 'components/Sidebar';
import { checkCustomerCustomization } from 'components/CustomerCustomizationWrapper';
import {
  Settings,
  Add,
  Search,
  Link,
  Company,
  Sidebar as SidebarIcon,
  Calendar,
  LoupeDocumentSearch,
  Tag,
  Key,
  WrongCircle,
  Notification,
  Home,
  Code,
} from '@combateafraude/icons/general';
import { Flow } from '@combateafraude/icons/builder';
import { Admin, Builder, Dashboard, Queries, Tools } from '@combateafraude/icons/trust';
import { AutenticaçãoFacial, Face, ProvaDeVida } from '@combateafraude/icons/face';
import { EmployeeComputerLaptop, Person, Users } from '@combateafraude/icons/users';
import { SecurityLockShield, ShieldProtected } from '@combateafraude/icons/security';
import { Money } from '@combateafraude/icons/financial';
import { useTranslation } from 'react-i18next';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useRegisterModal } from 'hooks/registerModal';
import usePreviewModal from 'customHooks/usePreviewModal';
import './styles.less';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import classNames from 'classnames';
import { ReactComponent as BusinessChartSidebar } from 'assets/images/business-chart-sidebar.svg';
import { ReactComponent as DashboardOnboardingIcon } from 'assets/images/dashboard-onboarding.svg';
import { ReactComponent as DashboardTransactionIcon } from 'assets/images/dashboard-transaction.svg';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');
const SESSION_EXPIRES_IN = isLocalhost
  ? 'CAF_session_expires_in'
  : '__Secure-CAF_session_expires_in';
const oneHourMilliseconds = 1000 * 60 * 60;

const Wrapper = ({ subheader, hideMainHeader, customClassName, children, ...rest }) => {
  const { handleScroll } = useScroll();
  const { idleTimeoutInMinutes: inactivityTimeoutInMinutes } = useSecurityOptions();
  const { openRegisterModal } = useRegisterModal();
  const { pathname } = useLocation();
  const { pfdPrintRef } = useHeaderFilter();
  const { t: translate } = useTranslation();
  const { user, signOut, isAdmin } = useAuth();

  const [collapsedSidebar, setCollapsedSidebar] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [signOutWarningType, setSignOutWarinigType] = useState(null);

  const userFeatureFlags = user.accountData.featureFlags || {};
  const isWorkflowBuilder = userFeatureFlags?.isWorkflowBuilder;
  const showGlobalDashboard = userFeatureFlags?.showGlobalDashboard;

  const exportDashboardPdf = useReactToPrint({
    content: () => pfdPrintRef.current,
    bodyClass: 'print-body',
  });
  const exportOnboardingStatisticsPdf = useReactToPrint({
    content: () => pfdPrintRef.current,
    bodyClass: 'print-body',
  });

  const { Modal: XLSPreviewModal, fetchPreviewUrl: fetchXlsPreview } = usePreviewModal({
    type: 'xls',
    url: '/analytics/export-dashboard',
  });

  const { Modal: CSVPreviewModal, fetchPreviewUrl: fetchCsvPreview } = usePreviewModal({
    type: 'csv',
    url: '/analytics/export-dashboard',
  });

  const {
    Modal: StatisticsXLSPreviewModal,
    fetchPreviewUrl: fetchStatisticsXlsPreview,
  } = usePreviewModal({
    type: 'xls',
    url: '/analytics/export-onboarding-statistics',
  });

  const {
    Modal: StatisticsCSVPreviewModal,
    fetchPreviewUrl: fetchStatisticsCsvPreview,
  } = usePreviewModal({
    type: 'csv',
    url: '/analytics/export-onboarding-statistics',
  });

  const facesPermission = useMemo(() => {
    if (hasRequiredPermissions(user, ['private-faceset:read'], [], false, 'management'))
      return '/faces-base/private-faceset';
    if (hasRequiredPermissions(user, ['mobile-faces:read'], [], false, 'management'))
      return '/faces-base/mobile-faces';

    return '/users-permissions/users';
  }, [user]);

  const idleTimeoutInMinutes =
    user?.accountData?.securityParams?.idleTimeoutInMinutes || inactivityTimeoutInMinutes;

  const verifySessionExpiration = useCallback(() => {
    const expiresInMilliseconds = Cookies.get(SESSION_EXPIRES_IN);
    const expiresInAlertMilliseconds = expiresInMilliseconds - oneHourMilliseconds;

    if (Date.now() > expiresInAlertMilliseconds) {
      setSignOutWarinigType('continuousUsage');
      setRemainingTime(oneHourMilliseconds);
    } else {
      setSignOutWarinigType(null);
      setRemainingTime(null);
    }
  }, []);

  const { reset, getRemainingTime } = useIdleTimer({
    onPrompt: () => {
      if (idleTimeoutInMinutes > 0) {
        setSignOutWarinigType('onIdleTimeout');
        setRemainingTime(getRemainingTime());
      }
    },
    onIdle: () => {
      if (idleTimeoutInMinutes > 0) {
        Cookies.set('DISCONNECTED_REASON', 'onIdleTimeout');
        signOut();
      }
    },
    onAction: () => {
      if (idleTimeoutInMinutes > 0) {
        reset();
        verifySessionExpiration();
      }
    },
    timeout: idleTimeoutInMinutes ? 1000 * 60 * (idleTimeoutInMinutes - 1) : 0,
    promptTimeout: 1000 * 60 * 1,
    startOnMount: true,
    events: ['mousedown'],
  });

  useEffect(() => {
    verifySessionExpiration();
  }, [verifySessionExpiration]);

  const handleCollapse = (collapse) => {
    setCollapsedSidebar(collapse);
  };

  const routeConfigs = useMemo(
    () => [
      {
        id: 'home',
        label: translate(`pages.private.wrapper.sidebar.home.label`),
        Icon: Home,
        header: {
          notifications: true,
        },
        menuHidden: (() => {
          if (isWorkflowBuilder) return false;
          if (showGlobalDashboard) return false;
          return true;
        })(),
        path: '/home',
      },
      {
        id: 'dashboard',
        label: translate(`pages.private.wrapper.sidebar.dashboard.label`),
        Icon: Dashboard,
        header: {
          filters: true,
          export: [
            {
              key: 'csv_dashboard',
              label: translate('components.exportOptions.options.csv'),
              onClick: fetchCsvPreview,
            },
            {
              key: 'xls_dashboard',
              label: translate('components.exportOptions.options.xls'),
              onClick: fetchXlsPreview,
            },
            {
              key: 'pdf_dashboard',
              label: translate('components.exportOptions.options.pdf'),
              onClick: exportDashboardPdf,
            },
          ],
          notifications: true,
        },
        menuHidden: isWorkflowBuilder || showGlobalDashboard,
        path: '/dashboard',
      },
      {
        id: 'global-dashboard',
        label: translate(`pages.private.wrapper.sidebar.dashboard.label`),
        Icon: BusinessChartSidebar,
        header: {
          filters: false,
          export: [],
          notifications: true,
        },
        menuHidden: (() => {
          if (isWorkflowBuilder) return false;
          if (showGlobalDashboard) return false;
          return true;
        })(),
        children: [
          {
            id: 'global-dashboard-transactions',
            label: translate(
              `components.header.horizontalNav.dropdown.dashboard.transactions`
            ),
            path: '/global-dashboard/transactions',
            Icon: DashboardTransactionIcon,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'global-dashboard-onboarding',
            label: translate(
              `components.header.horizontalNav.dropdown.dashboard.onboarding`
            ),
            path: '/global-dashboard/onboarding',
            Icon: DashboardOnboardingIcon,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          // {
          //   id: 'global-dashboard-liveness',
          //   label: translate(
          //     `components.header.horizontalNav.dropdown.dashboard.liveness`
          //   ),
          //   path: '/global-dashboard/liveness',
          //   Icon: DashboardLivenessIcon,
          //   header: {
          //     filters: false,
          //     export: [],
          //     notifications: true,
          //   },
          //   menuHidden: false,
          // },
        ],
      },
      {
        id: 'queries',
        label: translate(
          isWorkflowBuilder
            ? `pages.private.wrapper.sidebar.verifications.label`
            : `pages.private.wrapper.sidebar.queries.label`
        ),
        Icon: Queries,
        header: {
          filters: false,
          export: [],
          notifications: true,
        },
        menuHidden: false,
        children: [
          {
            id: 'new-onboarding',
            label: translate('components.header.index.newOnboarding.button'),
            onClick: openRegisterModal,
            permissions: {
              requiredPermissions: [
                'people:create',
                'companies:create',
                'onboardings:create',
                'executions:create',
              ],
              or: true,
            },
            Icon: Add,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'kyb-company-searching',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.KYB.title',
              'Company'
            ),
            path: '/company?tab=companySearch',
            Icon: Company,
            header: {
              filters: false,
              export: false,
              notifications: true,
            },
            menuHidden: !isWorkflowBuilder,
          },
          {
            id: 'executions',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.person.title'
            ),
            path: `/person?createdDate=anyDate`,
            Icon: Person,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: !isWorkflowBuilder,
            permissions: { requiredPermissions: ['executions:read'] },
          },
          {
            id: 'executions',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.executions.title'
            ),
            path: `/executions?createdDate=anyDate`,
            Icon: Search,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: isWorkflowBuilder,
            permissions: { requiredPermissions: ['executions:read'] },
          },
          {
            id: 'onboarding-links',
            label: translate(
              'components.header.horizontalNav.dropdownProfileTemplatesMenu.navLink.onboardingLinks.title'
            ),
            path: '/onboarding-links?active=true',
            Icon: Link,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,

            permissions: { requiredPermissions: ['onboardings:read'] },
          },
          {
            id: 'face-authenticator',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.faceAuthenticator.title'
            ),
            path: '/face-authenticator',
            Icon: AutenticaçãoFacial,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: isWorkflowBuilder,
            permissions: { requiredPermissions: ['face-authenticator:read'] },
          },
          {
            id: 'liveness',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.liveness.title'
            ),
            path: '/liveness',
            Icon: ProvaDeVida,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
            permissions: { requiredPermissions: ['faceliveness:read'] },
          },
          ...(userFeatureFlags.profilesModule
            ? [
                {
                  id: 'people',
                  label: !isWorkflowBuilder
                    ? translate(
                        'components.header.horizontalNav.dropdownProfiles.menus.people.title'
                      )
                    : translate(
                        'components.header.horizontalNav.dropdownProfiles.menus.profiles.title'
                      ),
                  path: `/profile/people?_type=${translate(
                    'components.header.horizontalNav.type.person'
                  )}`,
                  Icon: Person,
                  header: {
                    filters: false,
                    export: [],
                    notifications: true,
                  },
                  menuHidden: false,
                  permissions: { requiredPermissions: ['people:read'] },
                },
              ]
            : []),
          {
            id: 'companies',
            label: translate(
              'components.header.horizontalNav.dropdownProfiles.menus.companies.title'
            ),
            path: `/profile/companies?_type=${translate(
              'components.header.horizontalNav.type.company'
            )}`,
            Icon: Company,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: !(userFeatureFlags?.profilesModule && !isWorkflowBuilder),
            permissions: { requiredPermissions: ['companies:read'] },
          },
        ],
      },
      {
        id: 'onboarding-builder',
        label: translate(`pages.private.wrapper.sidebar.builder.label`),
        Icon: Builder,
        header: {
          filters: false,
          export: [],
          notifications: true,
        },
        menuHidden: false,
        path: '/onboarding-builder',
        permissions: { requiredPermissions: ['onboarding-builder:read'] },
      },
      {
        id: 'tools',
        label: translate(`pages.private.wrapper.sidebar.tools.label`),
        Icon: Tools,
        header: {
          filters: false,
          export: [],
          notifications: true,
        },
        menuHidden: false,
        children: [
          {
            id: 'workflow-builder',
            label: translate(
              'components.header.horizontalNav.dropdownTools.menu.workflows'
            ),
            fullScreen: true,
            path: '/workflows',
            Icon: Flow,
            menuHidden: !isWorkflowBuilder,
            permissions: { requiredPermissions: ['profile-templates:read'] },
          },
          {
            id: 'profile-templates',
            label: translate(
              'components.header.horizontalNav.dropdownTools.menu.profileModels'
            ),
            path: '/profile-templates/profile-models?active=true',
            Icon: SidebarIcon,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: isWorkflowBuilder,
            permissions: { requiredPermissions: ['profile-templates:read'] },
          },
          {
            id: 'routines',
            label: translate(
              'components.header.horizontalNav.dropdownTools.menu.routines'
            ),
            Icon: Calendar,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: !(userFeatureFlags.profilesModule && !isWorkflowBuilder),
            path: '/routines',
            permissions: { requiredPermissions: ['routines:read'] },
          },
        ],
      },
      ...(isAdmin
        ? [
            {
              id: 'admin',
              label: translate(`pages.private.wrapper.sidebar.admin.label`),
              Icon: Admin,
              header: {
                filters: false,
                export: [],
                notifications: true,
              },
              menuHidden: false,
              permissions: {
                requiredPermissions: [
                  'security-configuration:read',
                  'security-configuration:update',
                ],
              },
              children: [
                {
                  id: 'audit-log',
                  label: translate(`pages.private.securityAndPrivacy.auditLog.menuTitle`),
                  path: '/security-and-privacy/audit-log',
                  permissions: { requiredPermissions: ['audit-log:read'] },
                  Icon: LoupeDocumentSearch,
                  header: {
                    filters: false,
                    export: [],
                    notifications: true,
                  },
                  menuHidden: false,
                },
                {
                  id: 'security-configuration',
                  label: translate(
                    `pages.private.securityAndPrivacy.securityConfiguration.menuTitle`
                  ),
                  path: '/security-and-privacy/security-configuration',
                  Icon: SecurityLockShield,
                  header: {
                    filters: false,
                    export: [],
                    notifications: true,
                  },
                  menuHidden: false,
                },
                ...(userFeatureFlags.livenessBlock
                  ? [
                      {
                        id: 'liveness-configuration',
                        label: translate(
                          `pages.private.securityAndPrivacy.livenessConfiguration.menuTitle`
                        ),
                        path: '/security-and-privacy/liveness-configuration',
                        Icon: EmployeeComputerLaptop,
                        header: {
                          filters: false,
                          export: [],
                          notifications: true,
                        },
                        menuHidden: false,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      {
        id: 'settings',
        label: translate(`pages.private.wrapper.sidebar.settings.label`),
        Icon: Settings,
        header: {
          filters: false,
          export: [],
          notifications: true,
        },
        menuHidden: false,
        children: [
          {
            id: 'user-permissions',
            label: translate(`components.managementMenu.menu.usersAndPermissions`),
            path: '/users',
            permissions: {
              requiredPermissions: ['users:read', 'permissions:read'],
              or: true,
              product: 'management',
            },
            Icon: Users,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'groups-permissions',
            label: translate(`components.managementMenu.menu.usersAndPermissions`),
            path: '/groups',
            permissions: {
              requiredPermissions: ['users:read', 'permissions:read'],
              or: true,
              product: 'management',
            },
            Icon: Users,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: true,
          },
          {
            id: 'consumption',
            label: userFeatureFlags.costPrices
              ? translate(`components.managementMenu.menu.consumptionFlag`)
              : translate(`components.managementMenu.menu.consumption`),
            path: '/consumption',
            permissions: {
              requiredPermissions: ['consumption:read'],
              product: 'management',
            },
            Icon: Money,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'tags-and-reasons',
            label: translate(`components.managementMenu.menu.tagsAndReasons`),
            path: '/tags-and-reasons',
            Icon: Tag,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'notifications',
            label: translate(`components.managementMenu.menu.notificationCenter`),
            path: '/notifications',
            Icon: Notification,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'faces-base',
            label: translate(`components.managementMenu.menu.facesBase`),
            path: facesPermission,
            permissions: {
              requiredPermissions: ['private-faceset:read', 'mobile-faces:read'],
              or: true,
              product: 'management',
            },
            Icon: Face,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: isWorkflowBuilder,
          },
          {
            id: 'faces-base',
            label: translate(`components.managementMenu.menu.facesBase`),
            path: '/faces-base/mobile-faces',
            permissions: {
              requiredPermissions: ['mobile-faces:read'],
              or: true,
              product: 'management',
            },
            Icon: Face,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: true,
          },
          {
            id: 'integration-guide',
            label: translate(`components.managementMenu.menu.accessKeys`),
            path: '/integration-guide',
            permissions: {
              requiredPermissions: ['access-keys:read'],
              product: 'management',
            },
            Icon: Code,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: false,
          },
          {
            id: 'access-keys',
            label: translate(`components.managementMenu.menu.accessKeys`),
            path: '/access-keys',
            permissions: {
              requiredPermissions: ['access-keys:read'],
              product: 'management',
            },
            Icon: Key,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: true,
          },

          {
            id: 'restrictions',
            label: translate(`components.managementMenu.menu.restrictions`),
            path: '/restrictions',
            permissions: {
              requiredPermissions: ['restrictive-list:read', 'deny-list:read'],
              or: true,
            },
            Icon: WrongCircle,
            header: {
              filters: false,
              export: [],
              notifications: true,
            },
            menuHidden: isWorkflowBuilder,
          },
          ...(checkCustomerCustomization(user, 'SKY')
            ? [
                {
                  id: 'blocked-documents',
                  label: translate(`components.managementMenu.menu.blockedDocuments`),
                  path: '/blocked-documents',
                  permissions: {
                    requiredPermissions: ['blocked-documents:read'],
                  },
                  Icon: ShieldProtected,
                  header: {
                    filters: false,
                    export: [],
                    notifications: true,
                  },
                  menuHidden: false,
                },
              ]
            : []),
        ],
      },
      {
        id: 'onboarding-statistics',
        label: translate(`pages.private.wrapper.sidebar.onboardingStatistics.label`),
        path: '/onboarding-statistics',
        header: {
          filters: true,
          export: [
            {
              label: translate('components.exportOptions.options.csv'),
              onClick: fetchStatisticsCsvPreview,
            },
            {
              label: translate('components.exportOptions.options.xls'),
              onClick: fetchStatisticsXlsPreview,
            },
            {
              label: translate('components.exportOptions.options.pdf'),
              onClick: exportOnboardingStatisticsPdf,
            },
          ],
          notifications: true,
        },
        menuHidden: true,
      },
    ],
    [
      isWorkflowBuilder,
      exportDashboardPdf,
      exportOnboardingStatisticsPdf,
      facesPermission,
      fetchCsvPreview,
      fetchStatisticsCsvPreview,
      fetchStatisticsXlsPreview,
      fetchXlsPreview,
      isAdmin,
      openRegisterModal,
      translate,
      user,
      userFeatureFlags,
      showGlobalDashboard,
    ]
  );

  const filterSidebarConfig = useMemo(() => {
    return routeConfigs
      .filter(({ menuHidden }) => !menuHidden)
      .map((config) => {
        if (!config.children) {
          return config;
        }
        const filteredChildren = config.children.filter(
          (child) => child.menuHidden !== true
        );
        return { ...config, children: filteredChildren };
      });
  }, [routeConfigs]);

  const activeMenu = useMemo(() => {
    const normalizePath = (path) => {
      if (!path) return path;

      return path.split('?')[0];
    };

    const comparePath = (configPath, path) => {
      const normalizedConfigPath = normalizePath(configPath);
      if (!normalizedConfigPath) return false;

      const pathWithoutLastItem = path.split('/').slice(0, -1).join('/');
      const pathWithoutTwoLastItems = path.split('/').slice(0, -2).join('/');
      if (normalizedConfigPath === path) return true;
      if (normalizedConfigPath === pathWithoutLastItem) return true;
      if (normalizedConfigPath === pathWithoutTwoLastItems) return true;

      return false;
    };

    let activeMenu = null;

    routeConfigs.forEach((menu) => {
      if (menu.children) {
        const child = menu.children.find((child) => comparePath(child.path, pathname));
        if (child) {
          activeMenu = child;
        }
      } else if (comparePath(menu.path, pathname)) {
        activeMenu = menu;
      }
    });

    return activeMenu;
  }, [pathname, routeConfigs]);

  return (
    <Layout className="gx-app-layout" style={{ backgroundColor: '#f9f9f9' }}>
      <Layout.Sider
        collapsed={collapsedSidebar}
        style={{ zIndex: 40 }}
        className="sidebar"
        collapsible
        trigger={null}
      >
        <Sidebar
          handleCollapse={handleCollapse}
          collapsed={collapsedSidebar}
          config={filterSidebarConfig}
          activeMenu={activeMenu?.id}
        />
      </Layout.Sider>

      <Layout.Content
        id="main-content"
        className={classNames('gx-layout-content gx-container-wrap header-container', {
          fullScreen: activeMenu?.fullScreen,
        })}
        onScroll={handleScroll}
      >
        {activeMenu && !!activeMenu.header && (
          <Layout.Header className="header">
            <div className="header-opacity" />
            <DataHeader
              title={activeMenu?.label ?? ''}
              config={activeMenu?.header}
              user={user}
            />
          </Layout.Header>
        )}

        <div
          className={classNames(`gx-main-content-wrapper`, customClassName, {
            fullScreen: activeMenu?.fullScreen,
          })}
          {...rest}
        >
          {children}
        </div>
      </Layout.Content>
      {XLSPreviewModal}
      {CSVPreviewModal}
      {StatisticsXLSPreviewModal}
      {StatisticsCSVPreviewModal}
    </Layout>
  );
};

Wrapper.propTypes = {
  subheader: PropTypes.node,
  hideMainHeader: PropTypes.bool,
  customClassName: PropTypes.string,
};

Wrapper.defaultProps = {
  subheader: undefined,
  hideMainHeader: false,
  customClassName: '',
};

export default Wrapper;

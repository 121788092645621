import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/auth';
import { useRegisterModal } from 'hooks/registerModal';
import Button from 'components/Button';
import { Link, Company, Code } from '@combateafraude/icons/general';
import { Builder } from '@combateafraude/icons/trust';
import { Flow } from '@combateafraude/icons/builder';
import { Person } from '@combateafraude/icons/users';
import { ReactComponent as BusinessChart } from 'assets/images/business-chart.svg';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import Wrapper from '../wrapper';
import QuickAccessCard from './components/QuickAccessCard';

import './styles.less';

const Home = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { openRegisterModal } = useRegisterModal();

  const isWorkflowBuilder = hasFeatureFlag('isWorkflowBuilder', user);

  return (
    <Wrapper>
      <div className="quick-access__container">
        <h1 className="quick-access__title">
          {translate('pages.private.home.index.title', {
            userName: user.name,
          })}
        </h1>
        <Button
          shape="round"
          type="primary"
          className="flex no-mrg-btm center quick-access__new-query-button"
          onClick={openRegisterModal}
        >
          <i className="caf-ic_add font-size-18 mrg-right-5" />
          {translate('pages.private.home.index.newQuery')}
        </Button>
        <div className="quick-access__content-container">
          {[
            {
              title: translate('pages.private.home.index.quickAccess.dashboard.title'),
              description: translate(
                'pages.private.home.index.quickAccess.dashboard.description'
              ),
              Icon: BusinessChart,
              to: '/global-dashboard/transactions',
              permissions: { requiredPermissions: ['executions:read'] },
              isTagged: true,
              tagName: translate('pages.private.home.index.quickAccess.dashboard.tag'),
            },
            {
              title: translate(
                'pages.private.home.index.quickAccess.personHistory.title'
              ),
              description: translate(
                'pages.private.home.index.quickAccess.personHistory.description'
              ),
              Icon: Person,
              to: isWorkflowBuilder ? '/person' : '/executions',
              permissions: { requiredPermissions: ['executions:read'] },
            },
            {
              title: translate(
                'pages.private.home.index.quickAccess.companyHistory.title'
              ),
              description: translate(
                'pages.private.home.index.quickAccess.companyHistory.description'
              ),
              Icon: Company,
              to: isWorkflowBuilder
                ? '/company?tab=history'
                : '/profile/companies?type=Company entity',
              permissions: { requiredPermissions: ['companies:read'] },
            },
            {
              title: translate(
                'pages.private.home.index.quickAccess.onboardingLinks.title'
              ),
              description: translate(
                'pages.private.home.index.quickAccess.onboardingLinks.description'
              ),
              Icon: Link,
              to: '/onboarding-links?active=all',
              permissions: { requiredPermissions: ['onboardings:read'] },
            },
            {
              title: translate(
                isWorkflowBuilder
                  ? 'pages.private.home.index.quickAccess.workflowBuilder.title'
                  : 'pages.private.home.index.quickAccess.profileTemplates.title'
              ),
              description: translate(
                isWorkflowBuilder
                  ? 'pages.private.home.index.quickAccess.workflowBuilder.description'
                  : 'pages.private.home.index.quickAccess.profileTemplates.description'
              ),
              Icon: Flow,
              to: isWorkflowBuilder
                ? '/workflows'
                : '/profile-templates/profile-models?active=true',
              permissions: { requiredPermissions: ['profile-templates:read'] },
            },
            {
              title: translate(
                'pages.private.home.index.quickAccess.onboardingBuilder.title'
              ),
              description: translate(
                'pages.private.home.index.quickAccess.onboardingBuilder.description'
              ),
              Icon: Builder,
              to: '/onboarding-builder',
              permissions: { requiredPermissions: ['onboarding-builder:read'] },
            },
            {
              title: translate(
                'pages.private.home.index.quickAccess.apiConfigurations.title'
              ),
              description: translate(
                'pages.private.home.index.quickAccess.apiConfigurations.description'
              ),
              Icon: Code,
              to: '/integration-guide',
              permissions: {
                requiredPermissions: ['access-keys:read'],
                product: 'management',
              },
            },
          ].map((quickAccessProps) => (
            <QuickAccessCard {...quickAccessProps} />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default Home;

import { React, useState } from 'react';
import { useFetch } from 'services/hooks';
import Wrapper from 'pages/private/wrapper';
import { usePostDashboard, useIFrameLoading } from './hooks';
import './styles.less';
import DashboardLoading from './components/DashboardLoading';

const GlobalDashboard = () => {
  const { post, data, loading } = useFetch();
  const [isFrameLoading, setIsFrameLoading] = useState(true);

  usePostDashboard(post, setIsFrameLoading);
  useIFrameLoading(setIsFrameLoading);

  return (
    <Wrapper>
      {loading ? (
        <DashboardLoading /> // API request loading
      ) : (
        <>
          {isFrameLoading && <DashboardLoading />} {/** iframe event loading */}
          <iframe
            src={data}
            title="metabase-dashboard"
            className="iframe"
            hidden={isFrameLoading}
            allowTransparency
          />
        </>
      )}
    </Wrapper>
  );
};

export default GlobalDashboard;
